'use client'

import { Button } from '@/components/library/button'
import { Icon } from '@/icons/icon'
import { sendGTMEvent } from '@next/third-parties/google'
import Link from 'next/link'
import { useEffect } from 'react'

export const EmptyCart = () => {
  useEffect(() => {
    sendGTMEvent({
      ecommerce: null,
    })
    sendGTMEvent({
      event: 'enter_cart',
      ecommerce: {
        cart_id: '',
      },
    })
  }, [])

  return (
    <div className='flex min-h-[calc(100vh-452px)] flex-col place-content-center items-center'>
      <div className='flex max-w-[354px] flex-col place-content-center items-center'>
        <Icon color='dark' name='shopping-bag-hand-bag-2-ui-line-pro' />
        <div className='mb-8 mt-4 text-xl font-semibold'>
          Your Cart is empty
        </div>
        <div className='mb-8 text-center text-lg'>
          If you’re a member login to see your cart. Otherwise, let’s go Lasoo
          some deals!
        </div>
        <Link prefetch={false} href='/'>
          <Button variant='contained'>Continue Shopping</Button>
        </Link>
      </div>
    </div>
  )
}
